import React, {useState, useEffect} from 'react';
import './SinglePage.css'; // Optional for extra custom styles
import Header from "./../../components/controls/Header";
import Button from "./../../components/controls/Button";

export const SinglePage = ({children, onContactSubmit, head}) => {
    const latestArticles = [
        {
            title: 'Empowering Users with Free Data through Meaningful Ad Interactions',
            description: 'Marshi offers a mobile browser where users are rewarded with free data for engaging with ads. By answering simple questions, users can earn data to browse or redeem for electricity vouchers, creating a new way to interact with advertisements and access essential services.',
            link: '/case-study/marshi-reverse-billing',
            image: '/images/clients/marshi-logo.avif',
        },
        {
            title: 'Impactful Mining Analytics: Streamlining Data for Smarter Decisions',
            description: 'Good Code helped AVA simplify complex mining data through real-time tracking, alerts, and visual analytics, improving efficiency and decision-making. The solution streamlined operations while ensuring easy data interpretation for global clients.',
            link: '/case-study/ava-engineering',
            image: '/images/clients/AVA-Logo.webp'
        },
        {
            title: ' Connecting Talent with Opportunity: A Smart Matching Platform',
            description: 'Pairtree bridges the gap between employees and employers using an intelligent matching algorithm. Potential employees input their details, skills, and qualifications, while employers list job requirements. The platform streamlines the interview process and offers comprehensive reporting to track progress and match success.',
            link: '/case-study/pairtree-recruitment',
            image: '/images/clients/pairtree.webp'
        }
    ];
    const [isSidePanelVisible, setSidePanelVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(false);  // To track mobile screen size

    // Function to toggle the side panel visibility
    const toggleSidePanel = () => {
        setSidePanelVisible(!isSidePanelVisible);
    };

    // Effect hook to track window resizing
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1024) {  // lg and larger screens
                setIsMobile(false);  // Large screen
                setSidePanelVisible(true);  // Show side panel by default on large screens
            } else {
                setIsMobile(true);  // Small screen
                setSidePanelVisible(false);  // Hide side panel by default on small screens
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);  // Empty array means this runs only once when the component mounts

    return (
        <div className="flex flex-col text-white">
            {/* Head component at the top, stretching full width and with a height of 300px */}
            <div className="w-full h-[300px]">
                {React.cloneElement(head)}
            </div>

            {/* Main Content and Side Panel */}
            <div className="flex-grow containerGradient backdrop-blur-md shadow-lg flex flex-row gap-6 mt-4">
                {/* Main Content */}
                <div className="flex-grow">
                    {children}
                </div>

                {/* Side Panel */}
                <div
                    className={`z-0 fixed lg:relative top-0 right-3 bottom-6 transition-all ${isSidePanelVisible ? 'block w-72 sm:w-80 min-w-[400px]' : 'hidden md:hidden'} lg:block lg:w-96 bg-gray-800 shadow-lg z-50`}
                >
                    {/* Side Panel Content */}
                    <div className="z-10 flex flex-col items-start h-full min-w-[300px] p-4 sm:p-6 md:p-8 lg:p-10">
                        {/* Latest Articles */}
                        <div className="mb-8 flex-grow-0">
                            <Header level="h2" className="text-purple-300 mb-4">
                              More Case Studies
                            </Header>
                            <ul className="space-y-4">
                                {latestArticles.map((article, index) => (
                                    <li key={index} className="flex items-center gap-4">
                                        <div className="flex items-start gap-4">
                                            {/* Image */}
                                            <img
                                                src={article.image}
                                                alt={article.title}
                                                className="w-12 h-12 object-cover rounded-md"
                                            />
                                            {/* Article Content */}
                                            <div className="flex-1 text-white">
                                                {/* Title */}
                                                <a
                                                    href={article.link}
                                                    className="text-white hover:text-blue-300 underline hover:opacity-100 opacity-75 transition-opacity"
                                                >
                                                    <Header level="h4" className="text-purple-300 no-underline" padding="p-0">
                                                        {article.title}
                                                    </Header>
                                                </a>

                                                {/* Excerpt/Description */}
                                                <p className="text-sm opacity-80">{article.description}</p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Contact Form */}
                        <div>
                            <Header level="h2" className="text-purple-300 mb-4">
                                Contact Us
                            </Header>
                            <form
                                onSubmit={onContactSubmit}
                                className="space-y-4 sm:space-y-6"
                            >
                                <div>
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-blue-300"
                                    >
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        className="w-full mt-2 px-4 py-3 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                                        required
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium text-blue-300"
                                    >
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        className="w-full mt-2 px-4 py-3 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                                        required
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="message"
                                        className="block text-sm font-medium text-blue-300"
                                    >
                                        Message
                                    </label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        rows="4"
                                        className="w-full mt-2 px-4 py-3 bg-gray-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                                        required
                                    ></textarea>
                                </div>
                                <Button buttonType="default" size="lg" className="w-full">
                                    Send Message
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Floating Button (Always visible to expand or collapse side panel) */}
            <button
                onClick={toggleSidePanel}
                className="bg-purple-700 text-white p-4 rounded-full shadow-lg focus:outline-none fixed bottom-6 right-6 z-50"
            >
                <span className="text-lg">{isSidePanelVisible ? '×' : '☰'}</span>
            </button>
        </div>
    );
};

export default SinglePage;