import React from 'react';
import Header from "../controls/Header";

const UniqueSellingPoint = () => {
    return (
        <section className="bg-indigo-900 py-20">
            <div className="container mx-auto text-center text-white">
                <Header level="h2">We Transform Your Ideas into Impactful, Purpose-Driven Solutions:</Header>
                <div className="grid md:grid-cols-3 gap-0">
                    <div className="p-10">
                        <h3 className="text-xl font-semibold text-gray-200 mb-4">Accelerate Your Growth</h3>
                        <p className="text-lg text-gray-300">
                            We deliver scalable applications designed to drive revenue, reduce costs, and unlock new market opportunities for sustainable business expansion.
                        </p>
                    </div>
                    <div className="bg-indigo-800 p-10" >
                        <h3 className="text-xl font-semibold text-gray-200 mb-4">Align with Purpose</h3>
                        <p className="text-lg text-gray-300">
                            Our solutions are crafted with a focus on social impact, helping you build a brand that resonates with values-driven customers and stakeholders.
                        </p>
                    </div>
                    <div className="p-10">
                        <h3 className="text-xl font-semibold text-gray-200 mb-4">Empower Lasting Change</h3>
                        <p className="text-lg text-gray-300">
                            We create tools that don’t just meet immediate needs but also lay a foundation for a brighter future for your team, your customers, and the communities you serve.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UniqueSellingPoint;
