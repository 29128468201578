import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {AppRoutes} from './AppRoutes';
import Layout from './components/Layout'; // Layout component that includes NavMenu
import {TransitionProvider} from './contexts/TransitionContext';

const App = () => {
    return (
        <TransitionProvider> {/* Wrap everything that needs transition context */}
            <Layout>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const {element, ...rest} = route;
                        return <Route key={index} {...rest} element={element}/>;
                    })}
                </Routes>
            </Layout>
        </TransitionProvider>
    );
};

export default App;
