import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {getBlogs, getBlog} from '../../services/publicService';
import Head from "../../components/controls/Head"; // Adjust the import path as necessary

const BlogList = () => {
    const [posts, setPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState(null);

    // Fetch all blog posts
    useEffect(() => {
        async function getData() {
            const blogs = await getBlogs();
            console.log('Blogs data:', blogs);
            setPosts(blogs);
        }

        getData();
    }, []);


    // Fetch a single post by ID
    const fetchPost = async (id) => {
        var blog = await getBlog(id);
        setSelectedPost(blog);
    };

    if (selectedPost) {
        console.log('selectedPost',selectedPost);
        // Single post view
        return (
                <section className="blog py-20 text-center relative cosmic-gradient">
                    <div className="container mx-auto lg:px-12 containerGradient p-10 max-w-screen-xl">
                        <button
                            className=" mb-6 px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-700 float-right"
                            onClick={() => setSelectedPost(null)}
                        >
                            Back
                        </button>
                        <div className="flex items-center justify-start mb-6">
                            <img
                                src={selectedPost.imageUrl}
                                alt={selectedPost.title}
                                className="h-36 object-cover object-center mr-6 rounded-md"
                            />
                            <h1 className="text-4xl font-bold text-white">{selectedPost.title}</h1>
                        </div>
                        <div className="prose mx-auto text-left text-gray-300"
                             dangerouslySetInnerHTML={{__html: selectedPost.body}}></div>
                        <p className="mt-4 text-gray-400">Categories: {selectedPost.categories.join(", ")}</p>
                    </div>
                </section>
        )
            ;
    }

    return (
        <section className="py-20 text-center relative cosmic-gradient">
            <div className="container mx-auto px-6 lg:px-12">
                <h2 className="text-4xl font-bold text-white mb-12">Blog Posts</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {Array.isArray(posts) && posts.length > 0 ? (
                        posts.map((post) => (
                            <div
                                key={post.id}
                                className="containerGradient flex flex-col backdrop-blur-md rounded-lg p-10"
                            >
                                <div className="flex-1 opacity-100">
                                    <img
                                        src={post.imageUrl}
                                        alt={post.title}
                                        className="h-36 object-cover object-center mx-auto opacity-50 hover:opacity-100 transition-opacity grayscale hover:grayscale-0 rounded-md"
                                    />
                                </div>
                                <div className="flex flex-col justify-between h-full text-left text-gray-200">
                                    <h3 className="pt-4 text-2xl font-semibold mb-2">{post.title}</h3>
                                    <p className="mb-6 text-gray-300">
                                        Categories:{" "}
                                        {post.categories && Array.isArray(post.categories)
                                            ? post.categories.join(", ")
                                            : "No categories available"}

                                    </p>
                                    <button
                                        className="px-4 py-2 bg-purple-700 text-white rounded-md hover:bg-purple-600"
                                        onClick={() => fetchPost(post.id)}
                                    >
                                        Read More
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-gray-400">No blog posts available.</p>
                    )}
                </div>
            </div>
        </section>
    );
};


export default BlogList;
