import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";

const Quote = ({ title, description, buttonText, buttonLink }) => {
    return (
        <section className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white py-20 p-3">
            <div className="container mx-auto text-center">
                <h2 className="text-3xl font-bold mb-6">{title}</h2>
                <p className="text-lg mb-6">{description}</p>
                <Button to={buttonLink} >
                    {buttonText}
                </Button>
            </div>
        </section>
    );
};

export default Quote;