import React, { createContext, useState, useContext } from 'react';

const TransitionContext = createContext();

export const TransitionProvider = ({ children }) => {
    const [isFading, setIsFading] = useState(false);

    const triggerFadeAndNavigate = (navigateFn, to) => {
        setIsFading(true);
        setTimeout(() => {
            window.scrollTo(0, 0);
            navigateFn(to); // Perform navigation
            setIsFading(false);
        }, 100); // Duration matches your fade-out CSS
    };

    return (
        <TransitionContext.Provider value={{ isFading, triggerFadeAndNavigate }}>
            {children}
        </TransitionContext.Provider>
    );
};

export const useTransition = () => useContext(TransitionContext);
