import React from 'react';
import { Typewriter } from 'react-simple-typewriter';

const TypingAnimation = () => (
    <Typewriter
        words={['Mobile Apps', 'Web Applications', 'Api Integrations', 'UI Design', 'Business Analysis']}
        loop={true}        // Loop the animation
        cursor
        cursorStyle="_"
        typeSpeed={120}     // Typing speed
        deleteSpeed={80}    // Deleting speed
        delaySpeed={1000}   // Delay before next word
    />
);

export default TypingAnimation;
