import React from "react";
import Header from "../../components/controls/Header";
import Head from "../../components/controls/Head";
import Quote from "../../components/controls/Quote";
import BlockList from "../../components/controls/BlockList";
import ContactForm from "../../components/public/ContactForm";
import {Helmet} from "react-helmet";

const Contact = () => {
   
    return (
        <div className="bg-gray-900 text-white font-sans">
            <div>
                <Head
                    heading="Get Ready For A Life Changing Event"
                    subheading="Chat To Us"
                    headingColor="text-white"
                    subheadingColor="text-gray-300"
                    headingSize="text-5xl"
                    subheadingSize="text-xl"
                    padding="py-32"
                    backgroundPosition="top center"
                />
            </div>
          <div className="p-10">

              <Helmet>
                  <title>Contact // Good coder</title>
                  <meta name="description" content="Contact Us // Good coder" />
              </Helmet>
              
              <ContactForm/>
          </div>
        </div>
    );
};

export default Contact;