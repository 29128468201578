import React from 'react';

const Header = ({ level = "h1", children, color = "text-white", padding = 'pb-5' }) => { //p-10 pb-20
    const HeadingTag = level.toLowerCase();

    // Define Tailwind text size classes based on the heading level
    const textSize = {
        h1: "text-4xl font-bold",
        h2: "text-3xl font-semibold",
        h3: "text-2xl font-medium",
        h4: "text-xl font-medium",
        h5: "text-lg font-medium",
        h6: "text-base font-normal",
    }[level.toLowerCase()] || "text-3xl font-bold"; // Default to h1 size if level is invalid

    return (
        <HeadingTag className={`${textSize} ${color} ${padding} no-underline`}>
            {children}
        </HeadingTag>
    );
};

export default Header;
