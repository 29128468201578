import React, { useState } from 'react';
import authService from '../../services/authService'; // Adjust the import path as necessary
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState([]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "username") setUsername(value);
        if (name === "email") setEmail(value);
        if (name === "password") {
            setPassword(value);
            validatePassword(value); // Validate on password change
        }
        if (name === "confirmPassword") setConfirmPassword(value);
    };

    const validatePassword = (password) => {
        const errors = [];
        if (password.length < 6) errors.push("Password must be at least 6 characters long.");
        if (!/[A-Z]/.test(password)) errors.push("Password must contain at least one uppercase letter.");
        if (!/[a-z]/.test(password)) errors.push("Password must contain at least one lowercase letter.");
        if (!/[0-9]/.test(password)) errors.push("Password must contain at least one number.");
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) errors.push("Password must contain at least one special character.");
        setPasswordErrors(errors);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Check for password validation errors
        if (passwordErrors.length > 0) {
            setErrorMessage('Please meet all password requirements.');
            return;
        }

        // Check for password match
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match.');
            return;
        }

        setLoading(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            const response = await authService.register(username, email, password);

            if (response && response.data && response.data.errors) {
                const errors = response.data.errors.map(err => err.description).join(" ");
                setErrorMessage(errors);
            } else {
                setSuccessMessage('Registration successful! You can now log in.');
            }
        } catch (error) {
            console.error('Registration error:', error);
            if (error.response && error.response.data && error.response.data.errors) {
                const errors = error.response.data.errors.map(err => err.description).join(" ");
                setErrorMessage(errors);
            } else {
                setErrorMessage('Registration failed. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="flex flex-col items-center justify-center min-h-screen text-white p-4">
            <Helmet>
                <title>Register // Good coder</title>
                <meta name="description" content="Register // Good coder" />
            </Helmet>
            
            <h1 className="text-4xl font-bold mb-4">Register</h1>
            {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

            {successMessage ? (
                <div className="bg-green-800 p-6 rounded-lg shadow-md w-full max-w-sm text-center">
                    <p className="text-green-500 mb-4">{successMessage}</p>
                    <Link
                        to="/login"
                        className="inline-block py-2 px-4 bg-blue-600 text-white font-semibold rounded-md shadow-sm hover:bg-blue-500 transition duration-300"
                    >
                        Go to Login
                    </Link>
                </div>
            ) : (
                <form onSubmit={handleSubmit} className="bg-gray-800 p-6 rounded-lg shadow-md w-full max-w-sm">
                    <div className="mb-4">
                        <label htmlFor="username" className="block text-sm font-medium text-white">
                            Username
                        </label>
                        <input
                            type="text"
                            name="username"
                            id="username"
                            value={username}
                            onChange={handleChange}
                            required
                            className="text-gray-700 mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-white">
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={email}
                            onChange={handleChange}
                            required
                            className="text-gray-700 mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-sm font-medium text-white">
                            Password
                        </label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            value={password}
                            onChange={handleChange}
                            required
                            className="text-gray-700 mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
                        />
                        {passwordErrors.length > 0 && (
                            <ul className="text-red-500 text-sm mt-2">
                                {passwordErrors.map((error, index) => (
                                    <li key={index}>{error}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="mb-6">
                        <label htmlFor="confirmPassword" className="block text-sm font-medium text-white">
                            Confirm Password
                        </label>
                        <input
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={handleChange}
                            required
                            className="text-gray-700 mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={loading || passwordErrors.length > 0}
                        className={`w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md shadow-sm hover:bg-blue-500 transition duration-300 ${
                            loading ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                    >
                        {loading ? 'Registering...' : 'Register'}
                    </button>
                </form>
            )}

            {!successMessage && (
                <p className="mt-4 text-center text-gray-400">
                    Already have an account?{' '}
                    <Link to="/login" className="text-blue-500 hover:underline">
                        Login here
                    </Link>
                </p>
            )}
        </div>
    );
};

export default Register;
