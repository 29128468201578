import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import './Home.css';
import ServicesSection from "../components/home/ServicesSection";
import CaseStudies from "../components/home/HomeCaseStudy";
import ImageList from "../components/controls/ImageList";
import UniqueSellingPoint from "../components/home/UniqueSellingPoint";
import Header from "../components/controls/Header";
import Partnerships from "../components/home/Partnerships";
import Hero from "../components/home/Hero";
import Quote from "../components/controls/Quote";
import {Helmet} from "react-helmet";

const Home = () => {
    const clientLogos = [
        { src: '/images/clients/BetterBond-Logo.jpg', alt: 'BetterBond', href: '' },
        { src: '/images/clients/MortgageMax-Logo.jpg', alt: 'MortgageMax', href: '' },
        { src: '/images/clients/BetterSure-Logo.jpg', alt: 'BetterSure', href: '' },
        { src: '/images/clients/CashCrusaders-Logo.jpg', alt: 'Cash Crusaders', href: '' },
        { src: '/images/clients/SAPartners-Logo.jpg', alt: 'SA Partners', href: '' },
        { src: '/images/clients/StandardBank-Logo.jpg', alt: 'Standard Bank', href: '' },
        { src: '/images/clients/Liberty-Logo.jpg', alt: 'Liberty', href: '' },
        { src: '/images/clients/ZeroRated-Logo.jpg', alt: 'ZeroRated', href: '' },
        { src: '/images/clients/ASELPH-Logo.jpg', alt: 'ASELPH', href: '' },
        { src: '/images/clients/DataAssist-Logo.jpg', alt: 'DataAssist', href: '' },
        { src: '/images/clients/WhatIf-Logo.jpg', alt: 'WhatIf', href: '' },
        { src: '/images/clients/MTN-Logo.jpg', alt: 'MTN', href: '' }
    ];

    const techLogos = [
        { src: '/images/techlogos/Azure.webp', alt: 'Microsoft Azure', href: '' },
        { src: '/images/techlogos/Bootstrap.webp', alt: 'Bootstrap', href: '' },
        { src: '/images/techlogos/CSharpLogo.webp', alt: 'C# (C-Sharp)', href: '' },
        { src: '/images/techlogos/Css.webp', alt: 'CSS (Cascading Style Sheets)', href: '' },
        { src: '/images/techlogos/Html5.webp', alt: 'HTML5', href: '' },
        { src: '/images/techlogos/MySql.webp', alt: 'MySQL', href: '' },
        { src: '/images/techlogos/NodeJs.webp', alt: 'Node.js', href: '' },
        { src: '/images/techlogos/phpLogo.webp', alt: 'PHP', href: '' },
        { src: '/images/techlogos/RavenDB.webp', alt: 'RavenDB', href: '' },
        { src: '/images/techlogos/SQLServer.webp', alt: 'Microsoft SQL Server', href: '' },
        { src: '/images/techlogos/Svelte.webp', alt: 'Svelte', href: '' },
        { src: '/images/techlogos/Tailwind.webp', alt: 'Tailwind CSS', href: '' }
    ];


    function createStars(elementId, count) {
        const container = document.getElementById(elementId);
        for (let i = 0; i < count; i++) {
            const star = document.createElement('div');
            star.className = 'star';
            star.style.left = `${Math.random() * 100}%`;
            star.style.animationDelay = `${Math.random() * 15}s`;
            star.style.width = `${Math.random() * 3 + 2}px`; // Random width between 2px and 5px
            // star.style.height = star.style.width; // Keep the height the same as width for circular stars
            container.appendChild(star);
        }
    }

// Create stars when component mounts
    useEffect(() => {
        createStars('stars', 100);
        createStars('stars2', 50);
        createStars('stars3', 25);
        createStars('stars4', 40);
    }, []);


    return (
        <div className="bg-gray-900 text-white min-h-screen">

            <Helmet>
                <title>Home // Good coder</title>
                <meta name="description" content="Home // Good coder" />
            </Helmet>
            
            {/* Hero Section */}

            <div className="absolute inset-x-0 bottom-0 bg-repeat-x"></div>
            <Hero/>

            {/* Unique Selling Proposition (USP) */}

            <UniqueSellingPoint/>
            {/* Services Problem Agitation Solution (PAS) */}
            <ServicesSection/>
            <Partnerships/>
          
            <ImageList clientLogos={clientLogos} heading="Trusted by Leading Companies"/>

            <CaseStudies/>

            {/* Call-to-Action (CTA) */}
            <Quote
                title="Ready to Make a Difference?"
                description="Discover how our altruistic approach to software development can benefit your company and help you create lasting change."
                buttonText="Schedule a Consultation"
                buttonLink="/contact"
            />
            
            <ImageList clientLogos={techLogos} gridCols="grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-12"
                       heading="The right technology for the job" swap={true}/>
        </div>
    );
};

export default Home;
