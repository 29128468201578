import React from "react";
import Hero from "../home/Hero";


const BlockList = ({list = [], hoverClass="hover:bg-indigo-500", baseClass="bg-gray-800"}) => {
    return (
        <div className="bg-black">
            <div className="max-w-7xl mx-auto text-center p-10">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                    {list.map((listItem, index) => (
                        <div
                            key={index}
                            className={`${hoverClass} ${baseClass} rounded-lg shadow-lg p-6 flex flex-col items-center space-y-4 hover:shadow-xl transition duration-300`}
                        >
                            <img
                                src={listItem.icon}
                                alt={listItem.title}
                                className="w-16 h-16 mb-4"
                            />
                            <h3 className="text-xl font-semibold text-white">
                                {listItem.title}
                            </h3>
                            <p className="text-gray-300 text-sm">
                                {listItem.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default BlockList;