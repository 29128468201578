import React from "react";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom"; // Import useNavigate from React Router
import {useTransition} from '../../contexts/TransitionContext';

const Button = ({
                    scrollToTop = true,
                    children,
                    size = "md",
                    buttonType = "primary",
                    customBgColor = "",
                    className = "",
                    to = null, // Navigation link
                    ...props
                }) => {
    // Define shared and size-specific styles
    const sizeClasses = {
        sm: "text-sm py-1 px-2",
        md: "text-base py-2 px-4",
        lg: "text-lg py-3 px-6",
        xl: "text-xl py-4 px-8", // Added XL size
        xxl: "text-2xl py-5 px-10", // Added XXL size
    };

    const baseClasses = "rounded-md px-6 py-2 transition-all duration-500 ease-in-out";
    const shadowClasses = "hover:shadow-lg hover:shadow-2xl";
    const hoverTextClasses = "hover:text-white";
    const borderClasses = "border-2";
    const typeClasses = {
        primary: `text-white ${borderClasses} border-transparent bg-gradient-to-r from-indigo-600 via-blue-700 to-purple-800 ${hoverTextClasses} hover:border-indigo-500 hover:bg-transparent backdrop-blur-md ${shadowClasses}`,
        secondary: `text-white ${borderClasses} border-transparent bg-gradient-to-r from-indigo-400 via-blue-500 to-purple-800 ${hoverTextClasses} hover:border-indigo-500 hover:bg-transparent backdrop-blur-md ${shadowClasses}`,
        default: `bg-gray-800 text-gray-100 shadow-md shadow-gray-800/50 ${borderClasses} border-purple-800 ${hoverTextClasses} hover:bg-gradient-to-br hover:from-purple-600 hover:to-indigo-600 ${shadowClasses}`,
        outline: `${borderClasses} border-indigo-500 text-indigo-500 hover:bg-indigo-500 ${hoverTextClasses} shadow-lg shadow-indigo-500/50`,
    };

    const sizeClass = sizeClasses[size] || sizeClasses.md;
    const bgColorClass = customBgColor
        ? `bg-[${customBgColor}] hover:brightness-110`
        : typeClasses[buttonType] || typeClasses.primary;
    const combinedClasses = `${baseClasses} ${sizeClass} ${bgColorClass} ${className}`;

    const navigate = useNavigate();
    const {triggerFadeAndNavigate} = useTransition();

    const handleNavigation = () => {
        if (to) {
            triggerFadeAndNavigate(navigate, to); // Use fade transition with navigation
        }
    };

    // Render a button or a navigation link depending on the `to` prop
    return (
        <button
            className={combinedClasses}
            onClick={handleNavigation}
            {...props}
        >
            {children}
        </button>
    );
};

// Prop types
Button.propTypes = {
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOf(["sm", "md", "lg", "xl", "xxl"]),
    buttonType: PropTypes.oneOf(["primary", "secondary", "default", "outline"]),
    customBgColor: PropTypes.string,
    className: PropTypes.string,
    to: PropTypes.string, // Optional: path to navigate
    type: PropTypes.string
};

export default Button;
