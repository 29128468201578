import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Home from "./pages/Home";
import AVAEngineering from "./pages/case-study/AVAEngineering";
import MarshiReverseBilling from "./pages/case-study/MarshiReverseBilling";
import PairtreeRecruitment from "./pages/case-study/PairtreeRecruitment";
import Dashboard from "./pages/Dashboard";
import ProtectedRoute from './components/ProtectedRoute';
import Services from "./pages/public/services";
import Contact from "./pages/public/contact";
import BlogList from "./pages/blog/BlogList";

export const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/services',
    element: <Services />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/contact',
    element: <Contact />
  },
  {
    path: '/dashboard',
    element: <ProtectedRoute element={<Dashboard />} /> // Protect this route
  },
  {
    path: '/case-study/pairtree-recruitment',
    element: <PairtreeRecruitment />
  }, {
    path: '/case-study/ava-engineering',
    element: <AVAEngineering />
  }, {
    path: '/case-study/marshi-reverse-billing',
    element: <MarshiReverseBilling />
  },
  {
    path: '/blog',
    element: <BlogList />
  },
  
];
