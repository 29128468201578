import React, {useState} from "react";
import {sendContactEmail} from '../../services/publicService';
import Button from "../controls/Button";
const ContactForm = () => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        budget: 500000,
        projectType: "new-dev",
        description: "",
        hasBudget: false, // Tracks whether the checkbox is checked
        address: ''
    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phone: "",
        description: "",
        budget: "",
        hasBudget: "",
    });

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zAZ0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const validateField = (name, value) => {
        switch (name) {
            case "name":
                return value ? "" : "Name is required.";
            case "email":
                return value
                    ? validateEmail(value)
                        ? ""
                        : "Please enter a valid email."
                    : "Email is required.";
            case "phone":
                return value ? "" : "Phone number is required.";
            case "description":
                return value ? "" : "Description is required.";
            case "budget":
                return formData.hasBudget && !value
                    ? "Budget is required."
                    : "";
            case "hasBudget":
                return formData.hasBudget ? "" : "Do you have a budget?";
            default:
                return "";
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        // Clear error when the field is being changed
        if (name in errors) {
            setErrors((prevState) => ({
                ...prevState,
                [name]: "",
            }));
        }
    };

    const handleBudgetBlur = (e) => {
        const {name, value} = e.target;
        const error = value > 20000 ? '' : "Please confirm your budget is above R 20 000"
        setErrors((prevState) => ({
            ...prevState,
            [name]: error,
        }));
    };

    const handleCheckboxChange = (e) => {

        const {checked} = e.target;

        // Update the hasBudget value in the form data
        setFormData((prevState) => ({
            ...prevState,
            hasBudget: checked,
            budget: checked ? prevState.budget : "", // Clear the budget if unchecked
        }));

        setErrors((prevState) => ({
            ...prevState,
            hasBudget: "", // Explicitly clear the error for "Do you have a budget?"
            ...(checked ? {} : {budget: ""}), // Clear budget error if unchecked
        }));
    };

    const handleBlur = (e) => {
        const {name, value} = e.target;
        const error = validateField(name, value);
        setErrors((prevState) => ({
            ...prevState,
            [name]: error,
        }));
    };

    const handleKeyUp = (e) => {
        const {name, value} = e.target;
        const error = validateField(name, value);
        setErrors((prevState) => ({
            ...prevState,
            [name]: error,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        // Validate all fields before submitting
        let formErrors = {};
        for (const field in formData) {
            const error = validateField(field, formData[field]);
            if (error) {
                formErrors[field] = error;
            }
        }

        // If there are errors, don't submit the form
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        // Send form data to backend (API endpoint)
        try {
            setLoading(true);
            if (await sendContactEmail(formData)) {
                setSuccessMessage("Thank you for your good message!");
                setFormData({
                    name: "",
                    email: "",
                    phone: "",
                    budget: 5000,
                    projectType: "new-dev",
                    description: "",
                    hasBudget: false,
                });
            } else {
                setErrorMessage("Oops! Something went wrong. Please try again.");
            }
            setLoading(false);
        } catch (error) {
            alert("Error submitting form. Please try again later.");
        }
    };

    return (
        <div>
            {successMessage ? (
                <div className="max-w-lg min-h-full mx-auto p-6 text-white rounded-lg space-y-6">
                    <img src="/icons/checked.png" alt="Success" className="mb-4 mx-auto w-40 h-40"/>
                    <div className="bg-green-500 text-white text-center p-4 rounded-md">
                        {successMessage}
                    </div>
                </div>
            ) : (
                <>
                    <form onSubmit={handleSubmit}
                          className="max-w-lg mx-auto p-6 bg-black text-white shadow-lg rounded-lg space-y-6">
                        <h2 className="text-3xl font-semibold text-center mb-6">Contact Us</h2>


                        <div>
                            {/* Name and Email Fields */}
                            <div className="mb-4 flex space-x-4">
                                {/* Name Field */}
                                <div className="flex-1">
                                    <label htmlFor="name" className="block text-sm font-medium">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyUp={handleKeyUp}
                                        required
                                        className={`mt-2 w-full p-3 bg-gray-800 text-white border border-gray-700 rounded-md focus:ring-2 focus:ring-blue-500 hover:border-purple-500 ${
                                            errors.name ? "border-red-500" : ""
                                        }`}
                                    />
                                    {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                                </div>

                                {/* Email Field */}
                                <div className="flex-1">
                                    <label htmlFor="email" className="block text-sm font-medium">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyUp={handleKeyUp}
                                        required
                                        className={`mt-2 w-full p-3 bg-gray-800 text-white border border-gray-700 rounded-md focus:ring-2 focus:ring-blue-500 hover:border-purple-500 ${
                                            errors.email ? "border-red-500" : ""
                                        }`}
                                    />
                                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                                </div>
                            </div>

                            {/* Phone and Project Type Fields */}
                            <div className="mb-4 flex space-x-4">
                                {/* Phone Field */}
                                <div className="flex-1">
                                    <label htmlFor="phone" className="block text-sm font-medium">
                                        Phone Number
                                    </label>
                                    <input
                                        type="tel"
                                        id="phone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyUp={handleKeyUp}
                                        required
                                        className={`mt-2 w-full p-3 bg-gray-800 text-white border border-gray-700 rounded-md focus:ring-2 focus:ring-blue-500 hover:border-purple-500 ${
                                            errors.phone ? "border-red-500" : ""
                                        }`}
                                    />
                                    {errors.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
                                </div>

                                {/* Project Type Field */}
                                <div className="flex-1">
                                    <label htmlFor="projectType" className="block text-sm font-medium">
                                        Project Type
                                    </label>
                                    <select
                                        id="projectType"
                                        name="projectType"
                                        value={formData.projectType}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onKeyUp={handleKeyUp}
                                        required
                                        className={`mt-2 w-full p-3 bg-gray-800 text-white border border-gray-700 rounded-md focus:ring-2 focus:ring-blue-500 hover:border-purple-500 ${
                                            errors.projectType ? "border-red-500" : ""
                                        }`}
                                    >
                                        <option value="new-dev">New Development</option>
                                        <option value="maintenance">Maintenance</option>
                                        <option value="consulting">Consulting</option>
                                    </select>
                                </div>
                            </div>

                            {/* Description Field */}
                            <div className="mb-4">
                                <label htmlFor="description" className="block text-sm font-medium">
                                    Tell Us About Your Project
                                </label>
                                <textarea
                                    id="description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    required
                                    rows="4"
                                    className={`mt-2 w-full p-3 bg-gray-800 text-white border border-gray-700 rounded-md focus:ring-2 focus:ring-blue-500 hover:border-purple-500 ${
                                        errors.description ? "border-red-500" : ""
                                    }`}
                                />
                                {errors.description && <p className="text-red-500 text-sm">{errors.description}</p>}
                            </div>

                            {/* Budget Field (Conditional Rendering) */}
                            {formData.hasBudget && (
                                <div className="mb-4">
                                    <label className="block text-sm font-medium">Budget</label>
                                    <div className="flex items-center">
                                        <input
                                            type="number"
                                            id="budget"
                                            name="budget"
                                            value={formData.budget.toLocaleString()} // Display formatted value
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyUp={handleKeyUp}
                                            className={`mt-2 w-full p-3 bg-gray-800 text-white border border-gray-700 rounded-md focus:ring-2 focus:ring-blue-500 hover:border-purple-500 ${
                                                errors.budget ? "border-red-500" : ""
                                            }`}
                                        />
                                    </div>
                                    <label className="p-0.5 block text-xs font-medium text-gray-500">* Min Budget of R
                                        20
                                        000 expected</label>
                                    {errors.budget && <p className="text-red-500 text-sm">{errors.budget}</p>}
                                </div>


                            )}

                            {/* Budget Checkbox */}
                            <div>
                                {errors.hasBudget && (
                                    <p className="text-red-500 text-sm">{errors.hasBudget}</p>
                                )}
                            </div>
                            <div className="mb-4 flex items-center">

                                <input
                                    type="checkbox"
                                    id="hasBudget"
                                    name="hasBudget"
                                    checked={formData.hasBudget}
                                    onChange={handleCheckboxChange}
                                    className={`mr-2 ${
                                        errors.hasBudget ? "border-red-500" : ""
                                    }`}
                                />
                                <label htmlFor="hasBudget" className="text-sm">
                                    I have a budget
                                </label>

                            </div>

                            <div className="mb-4 hidden">
                                <label htmlFor="address" className="block text-sm font-medium">
                                    Address
                                </label>
                                <textarea
                                    id="address"
                                    name="address"
                                    value={formData.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyUp={handleKeyUp}
                                    rows="4"
                                    className={`mt-2 w-full p-3 bg-gray-800 text-white border border-gray-700 rounded-md focus:ring-2 focus:ring-blue-500 hover:border-purple-500 ${
                                        errors.description ? "border-red-500" : ""
                                    }`}
                                />
                                {errors.address && <p className="text-red-500 text-sm">{errors.address}</p>}
                            </div>


                            {/* Submit Button */}
                            <div className="mb-4">
                                <Button
                                    disabled={loading}
                                    size="lg"
                                    buttonType="primary"
                                    type="submit" // Ensures the form handles submit on click
                                    className="mt-4"
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </form>
                </>
            )}

        </div>
    );
};

export default ContactForm;
