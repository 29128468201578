import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const sendContactEmail = async (emailData) => {
    try {
        const response = await axios.post(`${API_URL}contact/send`, emailData);
        return response.data === 'Email sent successfully';
    } catch (error) {
        console.error("Contact error:", error);
        return false;
    }
};

const getBlogs = async () => {
    try {
        const response = await axios.get(`${API_URL}blog`); // Ensure this is a GET request
        return response.data;
    } catch (error) {
        console.error("Error fetching blogs:", error);
        return [];
    }
};

const getBlog = async (id) => {
    try {
        const response = await axios.get(`${API_URL}blog/${id}`);
        if (response.data) {
            return response.data;
        }
        console.error("Contact blog error:", response);
    } catch (error) {
        console.error("Blog x 2:", error);
        return false;
    }
};

export {
    sendContactEmail, getBlogs, getBlog
};
