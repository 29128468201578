import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext'; // Use the AuthContext

const Dashboard = () => {
    const { isLoggedIn } = useAuth();
    const [data, setData] = useState(null);

    useEffect(() => {
        console.log('Dashboard component mounted');

        if (isLoggedIn) {
            console.log('Fetching data for logged-in user');
            // Example API call for dashboard data
            // fetch('/api/dashboard') // Replace with your API endpoint
            //     .then((response) => response.json())
            //     .then((data) => {
            //         console.log('Dashboard data:', data);
            //         setData(data);
            //     })
            //     .catch((error) => {
            //         console.error('Error fetching dashboard data:', error);
            //     });
        } else {
            
        }
    }, [isLoggedIn]); // Runs when isLoggedIn changes

    if (!isLoggedIn) {
        return <div>You must log in to view the dashboard.</div>;
    }

    return (
        <div>
            <h1>Dashboard</h1>
            {data ? <pre>{JSON.stringify(data, null, 2)}</pre> : <p>Loading data...</p>}
        </div>
    );
};

export default Dashboard;
