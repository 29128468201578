import React from "react";
import {SinglePage} from "../templates/SinglePage";
import Head from "../../components/controls/Head";
import Header from "../../components/controls/Header"
import Button from "../../components/controls/Button"
import {Helmet} from "react-helmet";
const MarshiReverseBilling = () => {

    const handleContactSubmit = (e) => {
        e.preventDefault();
        alert("Form submitted!");
    };

    return (
        <SinglePage onContactSubmit={handleContactSubmit} head={<Head
            heading="Case Study: Marshi"
            subheading="Revolutionising Advertising with Free Data for Interactions"
            headingColor="text-white"
            subheadingColor="text-gray-300"
            headingSize="text-5xl"
            subheadingSize="text-xl"
            padding="py-32"
            backgroundPosition="top center"
        />} >

            <Helmet>
                <title>Marshi // Good coder</title>
                <meta name="description" content="Case Study: Marshi // Good coder" />
            </Helmet>
            
            <div className="bg-gray-900 text-white font-sans p-8">
                <section className="mb-12">
                    <Header level="h2" color="text-white" padding="p-5 pb-5">The Good Coder helped Marshi create a groundbreaking
                        platform that rewards users for engaging with ads.</Header>
                </section>

                <section className="space-y-8">
                    <h2 className="text-3xl">The Vision Behind Marshi</h2>
                    <p>
                        Marshi empowers users to engage with advertisements in a way that benefits them directly. Users
                        earn free data by answering simple questions after interacting with ads. This data can be
                        exchanged for valuable rewards like electricity vouchers or used to browse freely using the
                        Marshi browser. The Good Coder’s approach helped bring this user-centric, innovative
                        platform to life.
                    </p>


                    <h2 className="text-3xl">The Challenge: Overcoming Technical and Business
                        Hurdles</h2>
                    <p>
                        Marshi needed to partner with mobile network providers to offer free data to users. The Good Coder
                        tackled this and other technical barriers by creating a seamless data tracking, reporting, and
                        API integration system.
                    </p>

                    <div className="text-center">
                        <img src="/images/clients/MarshiBanner.jpg" alt="Marshi Vision"
                             className="mx-auto mb-8 rounded-md"/>
                    </div>

                    <h2 className="text-3xl">The Solution: The Good Coder's Strategic Approach</h2>
                    <h3 className="text-2xl text-white mt-6">Phase 1: Diagnostics and Blueprinting</h3>
                    <p>
                        The Good Coder began with a thorough diagnostics phase, setting clear business and technical goals.
                        We identified potential risks, outlined integration requirements, and made informed
                        architectural choices to support Marshi’s long-term success.
                    </p>
                    <div className="text-center lg:float-left lg:w-1/2 md:w-full mb-8 lg:mr-8">
                        <img src="/images/clients/MarshiAppScreenshot.jpg" alt="Technical Challenge"
                             className="w-full max-w-lg mx-auto mb-8 rounded-md"/>
                    </div>

                    <h3 className="text-2xl text-white mt-6">Phase 2: UI/UX Design and Collaboration</h3>
                    <p>
                        With high-fidelity mockups provided by Marshi’s design agency, The Good Coder’s team implemented
                        detailed process flows using Adobe XD to ensure a smooth user experience.
                    </p>

                    <h3 className="text-2xl text-white mt-6">Phase 3: The Coding Phase</h3>
                    <p>
                        The Good Coder’s team focused on building infrastructure for tracking, analysis, and logging. By
                        breaking the features into manageable components, we identified and solved critical challenges,
                        particularly with third-party API and network integrations.
                    </p>

                    <h3 className="text-2xl text-white mt-6">Phase 4: Project Management, Maintenance, and
                        Enhancement</h3>
                    <p>
                        After launching Marshi a month ahead of schedule, The Good Coder continued to provide ongoing
                        support, ensuring the platform’s growth and evolution with new features added each month.
                    </p>

                    <h2 className="text-3xl">Results: Rapid Growth and Meaningful Impact</h2>
                    <p>
                        Marshi launched a month ahead of schedule and reached 10,000 users in the first month. With
                        dashboards and reports, Marshi’s team can monitor ad performance, user engagement, and business
                        health, all while planning for future data-driven innovations.
                    </p>

                    <h2 className="text-3xl">The Future of Marshi: Data Commoditization</h2>
                    <p>
                        As Marshi grows, the ability to mine data and analyze user behavior opens up new opportunities
                        for growth and refinement. The platform’s integration with mobile network providers has set a
                        precedent for how data can be monetized in innovative and user-beneficial ways.
                    </p>

                    <h2 className="text-3xl">Conclusion: Embracing Disruptive Innovation</h2>
                    <p>
                        In a world dominated by profit-driven models, Marshi is a shining example of how technology can
                        be used for good. By commoditising data, Marshi allows users to earn valuable rewards, creating
                        a win-win scenario that benefits all parties.
                    </p>

                    <div className="text-center mt-8">
                        <p>Ready to bring your ideas to life? Let The Good Coder help you
                            transform your vision into reality.</p>
                        <Button to="/contact" size="lg">
                            Schedule a Consultation
                        </Button>
                    </div>
                </section>
            </div>
        </SinglePage>
    );
};

export default MarshiReverseBilling;