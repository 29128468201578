import React from 'react';
import './HomeCaseStudy.css';
import Header from "../controls/Header";
import Button from "../controls/Button";

const HomeCaseStudy = () => {
    const caseStudies = [
        {
            title: 'Empowering Users with Free Data through Meaningful Ad Interactions',
            description: 'Marshi offers a mobile browser where users are rewarded with free data for engaging with ads. By answering simple questions, users can earn data to browse or redeem for electricity vouchers, creating a new way to interact with advertisements and access essential services.',
            link: '/case-study/marshi-reverse-billing',
            image: '/images/clients/marshi-logo.avif'
        },
        {
            title: 'Impactful Mining Analytics: Streamlining Data for Smarter Decisions',
            description: 'Good Code helped AVA simplify complex mining data through real-time tracking, alerts, and visual analytics, improving efficiency and decision-making. The solution streamlined operations while ensuring easy data interpretation for global clients.',
            link: '/case-study/ava-engineering',
            image: '/images/clients/AVA-Logo.webp'
        },
        {
            title: ' Connecting Talent with Opportunity: A Smart Matching Platform',
            description: 'Pairtree bridges the gap between employees and employers using an intelligent matching algorithm. Potential employees input their details, skills, and qualifications, while employers list job requirements. The platform streamlines the interview process and offers comprehensive reporting to track progress and match success.',
            link: '/case-study/pairtree-recruitment',
            image: '/images/clients/pairtree.webp'
        }
    ];

    return (
        <section className="py-20 text-center relative cosmic-gradient">
            <div className="container mx-auto px-6 lg:px-12">
                <Header level="h2">  Lives We've Changed // Case Studies</Header>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {caseStudies.map((caseStudy, index) => (
                        <div
                            key={index}
                            className="containerGradient flex flex-col backdrop-blur-md rounded-lg p-10 mb-4"
                        >
                            <div className="flex-1 opacity-100">
                                <img
                                    src={caseStudy.image}
                                    alt={caseStudy.title}
                                    className="h-36 object-cover object-center mx-auto opacity-50 hover:opacity-100 transition-opacity grayscale hover:grayscale-0 rounded-md"
                                />
                            </div>
                            <div className="opacity-100 flex flex-col justify-between h-full text-left text-gray-200">
                                <h3 className="pt-4 text-2xl font-semibold mb-2">{caseStudy.title}</h3>
                                <p className="mb-6 text-gray-300">{caseStudy.description}</p>
                                <Button to={caseStudy.link} size="md" buttonType="default">
                                    Read More
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>

    );
};

export default HomeCaseStudy;
