import React from "react";
import {SinglePage} from "../templates/SinglePage";
import Head from "../../components/controls/Head";
import Header from "../../components/controls/Header";
import Button from "../../components/controls/Button";
import {Helmet} from "react-helmet";

const AVAEngineering = () => {

    const handleContactSubmit = (e) => {
        e.preventDefault();
        alert("Form submitted!");
    };

    return (
        <SinglePage onContactSubmit={handleContactSubmit} head={<Head
            heading="Case Study: AVA Solutions"
            subheading="Creating Quality Analytics For Better Business Decisions"
            headingColor="text-white"
            subheadingColor="text-gray-300"
            headingSize="text-5xl"
            subheadingSize="text-xl"
            padding="py-32"
            backgroundPosition="top center"
        />}>
            <Helmet>
                <title>AVA // Good coder</title>
                <meta name="description" content="Case Study: AVA // Good coder" />
            </Helmet>
            
            <div className="container mx-auto p-6 bg-black text-gray-200">
                <section className="mb-12">
                    <Header level="h2" color="text-white" padding="p-5 pb-5">
                        Profitability through Performance: Empowering the Mining Sector with Data-Driven
                        Solutions</Header>
                </section>

                <p>
                    In the mining sector, where vast operations require careful
                    management, <strong>profitability</strong> is the ultimate measure of success. Mining companies face
                    enormous expenses and revenues, and the key to sustaining profitability lies in effective management
                    of both staff and equipment. Ensuring that vehicles are running smoothly and that personnel are
                    operating efficiently can have a direct impact on the bottom line.
                </p>

                <p>
                    Historically, mining operations have generated a wealth of data, but making sense of that data has
                    often been a challenge. The complexity of operations and the number of factors at play made it
                    difficult to extract actionable insights from this wealth of information.
                </p>

                <p>

                    At <strong>The Good Coder</strong>, we believe that when <strong>data is used for good</strong>, it can
                    transform industries and positively impact communities. That’s why we partnered with <strong>Advanced
                    Vehicle Analytics (AVA)</strong> to help them unlock the power of their data and use it to improve
                    both profitability and sustainability in the mining sector.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">The Challenge: Turning Data into Actionable
                    Insights</h2>
                <p>
                    AVA, with its extensive experience in open-cast mining, faced a significant challenge—how to
                    simplify and make sense of the vast amounts of data being generated by mining equipment and
                    processes. New technological advancements, such as tracking mining equipment’s fuel usage, engine
                    performance, and maintenance schedules, were crucial, but the data needed to be extracted and
                    managed without disrupting the ongoing mining activities.
                </p>
                <div className="p-2 text-center lg:float-left lg:w-1/2 md:w-full mb-8 lg:mr-8">
                    <img src="/images/clients/AVAManagement.png" alt="Technical Challenge"
                         className="w-full max-w-lg mx-auto mb-8 rounded-md"/>
                </div>

                <p>
                    The goal was to collect vital intelligence without affecting current output, ensuring minimal impact
                    on daily operations.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Planning and Solutioning: A Vision for
                    Simplified, Meaningful Data</h2>
                <p>
                    The Good Coder worked closely with AVA to craft a solution that would help them unlock the value in their
                    data, simplifying it in a way that would be easy for mining managers to interpret and act on. By
                    applying our expertise in <strong>business analysis</strong>, <strong>UX/UI design</strong>,
                    and <strong>technology integration</strong>, we were able to design a streamlined solution that met
                    their needs.
                </p>

                <p>
                    The <strong>diagnostics phase</strong> identified the following critical components for success:
                </p>

                <ul className="list-inside list-disc text-lg text-gray-300 mb-6">
                    <li><strong>Real-Time Tracking</strong>: The ability to track vehicles in real-time was essential to
                        ensure efficient operations.
                    </li>
                    <li><strong>Alerts and Notifications</strong>: Alerts via SMS and email would notify mining managers
                        if KPIs or output levels weren’t being met, empowering them to make quick decisions.
                    </li>
                    <li><strong>Visual Replay of Activities</strong>: A feature that would allow mining managers to
                        visually replay mining activity to assess performance and identify inefficiencies.
                    </li>
                    <li><strong>Issue Tracking</strong>: The ability to explore existing data to pinpoint where
                        processes went wrong.
                    </li>
                    <li><strong>Visual Maps and Dashboards</strong>: Displaying data on maps of the mine, allowing
                        managers to track all activities in real-time on intuitive dashboards.
                    </li>
                </ul>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">The Design Process: Creating an Intuitive,
                    Impactful Experience</h2>
                <p>
                    Our design approach was rooted in creating a solution that would <strong>empower mining
                    managers</strong> with the information they needed without overwhelming them. By simplifying complex
                    data into visual elements that were easy to interpret, we ensured that the solution was not only
                    functional but also user-friendly.
                </p>
                <div className="p-2 text-center lg:float-left lg:w-1/2 md:w-full lg:mr-8">
                    <img src="/images/clients/AVA-Variances.png" alt="Technical Challenge"
                         className="w-full max-w-lg mx-auto mb-8 rounded-md"/>
                </div>

                <p>
                    The scope of our work focused on the <strong>diagnostic phase</strong> and <strong>UX/UI
                    design</strong>. This included:
                </p>

                <ul className="list-inside list-disc text-lg text-gray-300 mb-6">
                    <li><strong>Requirement Gathering</strong>: Understanding the technical and business requirements
                        that would make the solution successful.
                    </li>
                    <li><strong>Domain Knowledge</strong>: Gaining deep insight into the mining industry’s challenges
                        and the processes that would benefit from this solution.
                    </li>
                    <li><strong>User Role Analysis</strong>: Defining the different personas who would use the software
                        and their goals.
                    </li>
                    <li><strong>User Process Flows</strong>: Mapping out the workflows to ensure the solution would be
                        intuitive and efficient.
                    </li>
                </ul>

                <p>
                    The deliverables provided to AVA included a <strong>blueprint</strong> that outlined the project in
                    detail, including:
                </p>

                <ul className="list-inside list-disc text-lg text-gray-300 mb-6">
                    <li><strong>Personas</strong>: The different users and their goals.</li>
                    <li><strong>User Stories</strong>: Narratives that outlined how users would interact with the
                        system.
                    </li>
                    <li><strong>Wireframes and High-Fidelity Mockups</strong>: Detailed designs of the user interface to
                        guide development.
                    </li>
                    <li><strong>Visual Elements and Controls</strong>: Icons, fonts, colors, and CSS to ensure a
                        cohesive and visually appealing design.
                    </li>
                </ul>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Results: A Data-Driven Solution That’s
                    Expanding Globally</h2>
                <p>
                    The Good Coder’s work resulted in a comprehensive document that provided the detailed findings, personas,
                    process flows, and visual assets needed to guide the software development process. As of 2020, the
                    solution is fully operational, and AVA now serves clients across South Africa, Australia, and
                    beyond.
                </p>

                <p>
                    The solution has continued to grow, with additional analytics becoming crucial to AVA’s services. As
                    AVA expands into the <strong>Internet of Things (IoT)</strong>, they now offer clients a holistic
                    view of their mining operations, allowing them to track and optimize every aspect of their business.
                </p>

                <h2 className="text-2xl font-semibold text-white mt-8 mb-4">Conclusion: Making Sense of Data for a
                    Better Tomorrow</h2>
                <p>
                    The blueprint The Good Coder delivered played a pivotal role in the success of AVA’s solution. By
                    simplifying the vast amounts of data generated by mining operations, we enabled AVA to offer a
                    solution that is not only easily navigable but also rich with actionable insights.
                </p>

                <p>
                    In an industry as complex as mining, simplifying data is essential for <strong>increasing
                    profitability</strong> while maintaining operational efficiency. Through <strong>data-driven
                    insights</strong> and <strong>user-centric design</strong>, AVA is now able to help mining companies
                    improve performance, save costs, and ultimately, contribute to a more sustainable and profitable
                    future.
                </p>

                <p>
                    At The Good Coder, we are committed to leveraging <strong>technology for good</strong>—helping businesses
                    transform their data into meaningful, impactful solutions that make a positive difference in the
                    world.
                </p>
            </div>
        </SinglePage>
    );
};

export default AVAEngineering;

