import cosmicBackgroundImage from "../../assets/img/TechnologyBg.webp";
import TechnologyDeviceImage from "../../assets/img/TechnologyDevices.webp";
import TypingAnimation from "./TypingAnimation";
import Button from "../controls/Button";
import React from "react";
import Partnerships from "./Partnerships";

const Hero = () => {
    return <section className="m-0 p-0 relative min-h-[100vh] bg-cover bg-center"
                    style={{backgroundImage: `url(${cosmicBackgroundImage})`}}>
        {/*/!* Overlay *!/*/}
        <div className="absolute inset-0 bg-black opacity-30 z-10"></div>

        <div id="stars" className="star-layer min-h-[80vh]"></div>
        <div id="stars2" className="star-layer min-h-[80vh]"></div>
        <div id="stars3" className="star-layer min-h-[80vh]"></div>
        <div id="stars4" className="star-layer min-h-[80vh]"></div>

        {/* Content */}
        <div className="relative flex items-center justify-center min-h-[80vh] z-20">
            <div className="text-center text-white py-24">
                <div className="flex justify-center">
                    <img className="content-center" src={TechnologyDeviceImage} alt="Technology device icons"/>
                </div>
                <h1 className="text-5xl text-shadow leading-tight mb-4 text-gradient-to-r from-pink-500 via-indigo-600 to-purple-700">
                    // We Do Good <span className="font-bold text-purple-200"> <TypingAnimation/></span>
                    <br/>
                    One App at a Time.
                </h1>
                <p className="text-xl mb-8 text-gray-200 text-shadow">
                    Partner with us to build innovative web and mobile applications that drive positive impact
                </p>
                <Button to="/contact" size="lg">
                    Schedule a Consultation
                </Button>
                {/*<Link to="/contact"*/}
                {/*      className="text-white border-2 border-transparent bg-gradient-to-r from-indigo-600 via-blue-700 to-purple-800 rounded-md px-6 py-2 transition-all duration-500 hover:border-indigo-500 hover:text-indigo-500 hover:bg-transparent backdrop-blur-md hover:shadow-2xl">*/}
                {/*    Schedule a Consultation*/}
                {/*</Link>*/}
            </div>
        </div>

    </section>;
}
export default Hero;