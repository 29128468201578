import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + 'auth/';

const register = async (username, email, password) => {
    const response = await axios.post(`${API_URL}register`, {
        username: username,
        email: email,
        password: password
    });
    console.log('response', response);
    return response.data;
};

const login = async (username, password) => {
    try {
        const response = await axios.post(`${API_URL}login`, {
            username: username,
            password: password
        });
        return response.data; // This will return the token if successful
    } catch (error) {
        console.error("Login error:", error);
        throw error; // Re-throw to handle in your component
    }
};

export default {
    register,
    login,
};
