import React from "react";
import {SinglePage} from "../templates/SinglePage";
import Head from "../../components/controls/Head";
import Header from "../../components/controls/Header"
import Button from "../../components/controls/Button"
import {Helmet} from "react-helmet";


const PairTreeRecruitment = () => {
    const handleContactSubmit = (e) => {
        e.preventDefault();
 
    };

    return (
        <SinglePage
            onContactSubmit={handleContactSubmit}
            head={
                <Head
                    heading="Case Study: PairTree"
                    subheading="Revolutionizing Recruitment with Automation"
                    headingColor="text-white"
                    subheadingColor="text-gray-300"
                    headingSize="text-5xl"
                    subheadingSize="text-xl"
                    padding="py-32"
                    backgroundPosition="top center"
                />
            }
        >
            <Helmet>
                <title>PairTree // Good coder</title>
                <meta name="description" content="Case Study: PairTree // Good coder" />
            </Helmet>
            
            <div className="bg-gray-900 text-white font-sans p-8 mx-auto">
                <section className="text-center mb-12">
                    <Header
                        level="h2"
                        color="text-white"
                        padding="p-5 pb-5 text-center"
                    >
                        The Good Coder helped PairTree transform the recruitment process with a groundbreaking matching platform.
                    </Header>
                </section>

                <section className="space-y-8">
                    <Header padding="p-0" level="h2">
                        The Vision Behind PairTree
                    </Header>
                    <p className="text-lg text-white">
                        PairTree aimed to disrupt the recruitment industry by creating an automated platform that matches employee skills with employer requirements—saving time, reducing manual effort, and ensuring no valuable candidates are overlooked.
                    </p>

                    <Header padding="p-0" level="h2">
                        The Challenge: Overcoming Technical and Business Hurdles
                    </Header>
                    <p className="text-lg text-white">
                        Manual CV processing was the industry standard, but PairTree needed a web-based system that was aesthetically pleasing, user-friendly, and powerful enough to make accurate matches in record time.
                    </p>

                    <Header padding="p-0" level="h2">
                        The Solution: The Good Coder’s Strategic Approach
                    </Header>

                    {/* Flex Container for Images */}
                    <div className="flex flex-wrap lg:flex-nowrap items-start gap-4 justify-center mt-8 rounded-md">
                        <img
                            src="/images/clients/PairTreeQualifications.png"
                            alt="PairTree Vision"
                            className="w-full sm:w-1/2 lg:w-1/3 rounded-md"
                        />
                        <img
                            src="/images/clients/PairTreeDashboard.png"
                            alt="Challenges in Recruitment"
                            className="w-full sm:w-1/2 lg:w-1/3 rounded-md"
                        />
                        <img
                            src="/images/clients/PairTreeRequirements.png"
                            alt="PairTree UX Design"
                            className="w-full sm:w-1/2 lg:w-1/3 rounded-md"
                        />
                    </div>

                    <Header padding="p-0" level="h3" color="text-white" padding="mt-6">
                        Phase 1: Diagnostics and Blueprinting
                    </Header>
                    <p className="text-lg text-white">
                        The Good Coder conducted a comprehensive diagnostics phase, identifying risks, clarifying integration requirements, and defining an architectural roadmap for PairTree’s long-term success.
                    </p>

                    <Header padding="p-0" level="h3" color="text-white" padding="mt-6">
                        Phase 2: UI/UX Design and Collaboration
                    </Header>
                    <p className="text-lg text-white">
                        Working with Stratusolve, The Good Coder helped create PairTree’s brand identity. Using Adobe XD, high-fidelity mockups and detailed user flows were designed and tested to ensure an intuitive user experience.
                    </p>

                    <Header padding="p-0" level="h3" color="text-white" padding="mt-6">
                        Phase 3: The Coding Phase
                    </Header>
                    <p className="text-lg text-white">
                        The development team focused on building a robust matching algorithm and user-friendly interface. By breaking the project into manageable components, The Good Coder ensured smooth progress and rapid delivery.
                    </p>

                    <Header padding="p-0" level="h3" color="text-white" padding="mt-6">
                        Phase 4: Project Management, Maintenance, and Enhancement
                    </Header>
                    <p className="text-lg text-white">
                        The Good Coder managed timelines, coordinated communication, and provided continuous support after launch. Monthly feature updates ensured the platform remained innovative and adaptive to PairTree’s evolving needs.
                    </p>

                    <Header padding="p-0" level="h2">
                        Results: Saving Time and Automating Recruitment
                    </Header>
                    <p className="text-lg text-white">
                        With automated matching, dashboards, and real-time reports, PairTree now streamlines recruitment processes while planning future innovations with data-driven insights.
                    </p>

                    <Header padding="p-0" level="h2">
                        Conclusion: Leading the Way in Recruitment Innovation
                    </Header>
                    <p className="text-lg text-white">
                        PairTree exemplifies the power of automation to transform industries. With The Good Coder’s expertise, the platform is a game-changer in recruitment, delivering value to both employers and candidates.
                    </p>

                    <div className="text-center mt-8">
                        <p className="text-lg text-white">
                            Ready to bring your ideas to life? Let The Good Coder help you transform your vision into reality.
                        </p>
                        <Button to="/contact" size="lg">
                            Schedule a Consultation
                        </Button>
                    </div>
                </section>
            </div>
        </SinglePage>
    );
};

export default PairTreeRecruitment;