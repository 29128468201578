import React from 'react';
import Header from "../controls/Header";

const Partnerships = () => {
    return (
        <section className="m-0 md:p-10 sm:p-3 pt-10 pb-10 relative bg-cover bg-center">
            <div className="container mx-auto px-6">
                <div className="grid md:grid-cols-2 gap-12">
                    <div>
                        <Header level="h2">// Partner with The Good Coder Today</Header>
                        <Header padding="pb-3 pt-3" level="h3">Doing Good</Header>
                        <p className="text-lg text-gray-300">We assist in making a positive impact in the world</p>

                        <Header padding="pb-3 pt-3" level="h3">Reduce Operational Costs</Header>
                        <p className="text-lg text-gray-300">Our solutions help drive businesses forward through
                            streamlining processes</p>

                        <Header padding="pb-3 pt-3" level="h3">Increase Efficiency</Header>
                        <p className="text-lg text-gray-300">We assist in automating repetitive tasks allowing your
                            team
                            to save time and focus on high-impact work.</p>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <img src="/images/ProfilePic.png" alt="Marshi Vision"
                             className="mx-auto mb-8 rounded-md max-w-80"/>
                        <p className="text-sm text-gray-300">Louis Barnard - Owner</p>

                    </div>
                </div>
            </div>
        </section>

    );
};

export default Partnerships;
