import React from 'react';
import './ServicesSection.css'; // Import the CSS file
import ServiceBgImage from '../../assets/img/bgservices.webp'; // Adjust the path based on your file structure
import Header from "../controls/Header";

const services = [
    {
        title: 'Web Application Development',
        description: 'We develop scalable, secure web applications using frameworks like React, Svelte and .NET MVC with optimised UI and cloud integration for seamless performance.'
    },
    {
        title: 'Mobile App Development',
        description: 'We create cross-platform and native mobile apps, focusing on smooth UX and beautiful UI.'
    },
    {
        title: 'UI/UX Design',
        description: 'We design user-centric interfaces with Figma, ensuring visually appealing, accessible, and brand-aligned experiences.'
    },
    {
        title: 'Prototyping & MVPs',
        description: 'We build quick prototypes and MVPs using Node.js and .NET to validate ideas and go to market faster.'
    },
    {
        title: 'Strategy & Consulting',
        description: 'We provide expert consulting in digital strategy and Agile transformation to align tech stack with business goals and optimise operations.'
    },
    {
        title: 'API & Integrations',
        description: 'We develop and integrate custom REST APIs, connecting CRMs, payment gateways, and more to streamline data flow and functionality.'
    },
    {
        title: 'Agile Services',
        description: 'Boost productivity with Agile coaching in Scrum and Kanban, focusing on sprint planning, collaboration, and iterative development.'
    },
    {
        title: 'Coaching',
        description: 'We offer individual/team coaching in the software development industry and communication to enhance collaboration and adaptability in tech.'
    },
];

const ServicesSection = () => {
    return (
        <section className="bg-cover bg-center text-center"
                 style={{ backgroundImage: `url(${ServiceBgImage})` }}>
            <div className="container mx-auto flex flex-col p-10 mb-4">
                <Header level="h2">Our Services</Header>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-8">
                    {services.map((service) => (
                        <div
                            key={service.title}
                            className="w-full h-36 bg-gray-900 bg-opacity-50 backdrop-blur-lg rounded-lg relative overflow-hidden perspective transition-shadow duration-300 shadow-lg transform hover:scale-105"
                        >
                            <div
                                className="flip-card-inner transform transition-transform duration-700 hover:rotate-y-180">
                                {/* Front side */}
                                <div
                                    className="flip-card-front absolute w-full h-full flex flex-col items-center justify-center rounded-lg bg-gray-900 bg-opacity-80 text-white backface-hidden"
                                >
                                    <h3 className="text-2xl sm:text-lg font-semibold mb-2">{service.title}</h3>
                                </div>

                                {/* Back side */}
                                <div
                                    className="flip-card-back absolute w-full h-full flex flex-col items-center justify-center rounded-lg text-white bg-gradient-to-bl from-purple-600 to-indigo-600 rotate-y-180 backface-hidden"
                                >
                                    <p className="text-sm p-2">{service.description}</p>
                                </div>
                            </div>
                        </div>

                    ))}
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;
