import React from "react";
import Header from "../../components/controls/Header";
import Head from "../../components/controls/Head";
import Quote from "../../components/controls/Quote";
import BlockList from "../../components/controls/BlockList";
import {Helmet} from "react-helmet";

const Services = () => {
    const services = [
        {
            title: "Tech Consultation & Training",
            description:
                "Empowering your team with the technical knowledge and guidance needed to innovate and solve complex challenges.",
            icon: "/icons/lightbulb.png",
        },
        {
            title: "Business Strategy & Analysis",
            description:
                "We help turn your ideas into actionable strategies with detailed business requirements, ensuring alignment with your vision and goals.",
            icon: "/icons/strategy.png",
        },
        {
            title: "UI/UX Design",
            description:
                "Crafting user-centric designs that are intuitive, modern, and aligned with your brand identity to create memorable digital experiences.",
            icon: "/icons/web-design.png",
        },
        {
            title: "Software Development",
            description:
                "Developing scalable, high-quality software solutions tailored to your specific needs, leveraging the latest technologies.",
            icon: "/icons/programming.png",
        },
        {
            title: "Project Management",
            description:
                "End-to-end project management to ensure seamless delivery, from timelines to stakeholder communication and risk mitigation.",
            icon: "/icons/to-do-list.png",
        },
        {
            title: "Integration & Automation (incl API Development)",
            description:
                "Streamlining your workflows with custom integrations and automation tools to improve efficiency and reduce manual effort.",
            icon: "/icons/api.png",
        },
        {
            title: "MVP Development",
            description:
                "Building Minimum Viable Products (MVPs) to validate your ideas, gather user feedback, and accelerate time to market.",
            icon: "/icons/settings.png",
        },
        {
            title: "Market Research & Validation",
            description:
                "Helping you understand your target audience and validate your product-market fit through data-driven insights.",
            icon: "/icons/research.png",
        },
    ];

    return (
        <div className="bg-gray-900 text-white font-sans">
            <div className="w-full">
                <Head
                    heading="Your Idea Can Change The World"
                    subheading="Discover how The Good Coder transforms ideas into innovative, profitable solutions."
                    headingColor="text-white"
                    subheadingColor="text-white "
                    headingSize="text-5xl"
                    subheadingSize="text-xl"
                    padding="py-32"
                    backgroundPosition="top center"
                    icon="/icons/creativity.png"
                />
            </div>

            <Helmet>
                <title>Services // Good coder</title>
                <meta name="description" content="Services // Good coder" />
            </Helmet>
            
            <BlockList list={services} hoverClass="hover:bg-gray-800" baseClass="bg-indigo-500"/>
            <Quote
                title="Ready to Make a Difference?"
                description="Discover how our The Good Coder can benefit your company and help you create lasting change."
                buttonText="Schedule a Consultation"
                buttonLink="/contact"
                swap={true}
            />
        </div>
    );
};

export default Services;