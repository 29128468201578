import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import LogoImage from "../assets/img/Logo.webp";

export const NavMenu = () => {
    const { isLoggedIn, logout } = useAuth();
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const toggleMenu = () => setMenuOpen(!menuOpen);

    // Close menu when clicking outside of it
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (menuOpen && !e.target.closest('.nav-menu') && !e.target.closest('.menu-button')) {
                setMenuOpen(false);
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
    }, [menuOpen]);

    return (
        <header className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-lg sticky top-0 z-50">
            <nav className="container mx-auto flex justify-between items-center p-4">
                {/* Logo */}
                <Link to="/" className="flex items-center">
                    <h2 className="text-2xl font-extralight text-white hover:text-gray-300 transition duration-300 flex items-center">
                        <img className="max-h-6 mr-2" src={LogoImage} alt="Technology device icons" />
                        The <span className="font-bold"> Good </span> Coder
                    </h2>
                </Link>

                {/* Hamburger Icon for Small Screens */}
                <button
                    className="sm:hidden text-white hover:text-gray-300 transition duration-300 focus:outline-none menu-button"
                    onClick={toggleMenu}
                >
                    <svg
                        className="w-6 h-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 6h16M4 12h16m-7 6h7"
                        />
                    </svg>
                </button>

                {/* Navigation Links */}
                <div
                    className={`fixed inset-y-0 right-0 sm:static sm:bg-transparent sm:flex sm:flex-row sm:items-center sm:space-x-4 transition-transform transform ${
                        menuOpen ? 'translate-x-0 bg-gradient-to-r from-purple-700 to-indigo-800' : 'translate-x-full'
                    } sm:translate-x-0 sm:static z-50 sm:z-auto w-4/5 sm:w-auto p-4 sm:p-0 nav-menu`}
                >
                    {/* Close Button */}
                    <button
                        className="block sm:hidden text-white hover:text-gray-300 mb-4 focus:outline-none"
                        onClick={() => setMenuOpen(false)}
                    >
                        <svg
                            className="w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>

                    <Link
                        to="/"
                        className="block text-white hover:bg-purple-600 px-3 py-2 rounded-md text-sm font-medium transition duration-300"
                        onClick={() => setMenuOpen(false)}
                    >
                        Home
                    </Link>
                    <Link
                        to="/services"
                        className="block text-white hover:bg-purple-600 px-3 py-2 rounded-md text-sm font-medium transition duration-300"
                        onClick={() => setMenuOpen(false)}
                    >
                        Services
                    </Link>
                    <Link
                        to="/blog"
                        className="block text-white hover:bg-purple-600 px-3 py-2 rounded-md text-sm font-medium transition duration-300"
                        onClick={() => setMenuOpen(false)}
                    >
                        Articles
                    </Link>
                    <Link
                        to="/contact"
                        className="block bg-purple-600  text-white px-3 py-2 rounded-md text-sm font-medium transition duration-300"
                        onClick={() => setMenuOpen(false)}
                    >
                        Contact Us
                    </Link>
                    
                    {/*{isLoggedIn ? (*/}
                    {/*    <>*/}
                    {/*        <Link*/}
                    {/*            to="/dashboard"*/}
                    {/*            className="block text-white hover:bg-purple-600 px-3 py-2 rounded-md text-sm font-medium transition duration-300"*/}
                    {/*            onClick={() => setMenuOpen(false)}*/}
                    {/*        >*/}
                    {/*            Dashboard*/}
                    {/*        </Link>*/}
                    {/*        <button*/}
                    {/*            onClick={() => {*/}
                    {/*                handleLogout();*/}
                    {/*                setMenuOpen(false);*/}
                    {/*            }}*/}
                    {/*            className="block text-white hover:bg-red-600 px-3 py-2 rounded-md text-sm font-medium transition duration-300"*/}
                    {/*        >*/}
                    {/*            Logout*/}
                    {/*        </button>*/}
                    {/*    </>*/}
                    {/*) : (*/}
                    {/*    <>*/}
                    {/*        <Link*/}
                    {/*            to="/login"*/}
                    {/*            className="block text-white hover:bg-purple-600 px-3 py-2 rounded-md text-sm font-medium transition duration-300"*/}
                    {/*            onClick={() => setMenuOpen(false)}*/}
                    {/*        >*/}
                    {/*            Login*/}
                    {/*        </Link>*/}
                    {/*    </>*/}
                    {/*)}*/}
                </div>
            </nav>
        </header>
    );
};

export default NavMenu;
