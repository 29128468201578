import React from 'react';
import './ImageList.css';
import Header from "../controls/Header"; // Import the CSS file

const ImageList = ({ clientLogos = [], heading = "", gridCols = "grid-cols-3 sm:grid-cols-4 md:grid-cols-6" }) => {
    return (
        <section className="py-10 md:py-20 text-center bg-white">
            <div className="container mx-auto px-4">
                <Header level="h2" color="text-black">{heading}</Header>
                <div
                    className={`grid ${gridCols} gap-6 sm:gap-8 lg:gap-12 justify-items-center`}
                >
                    {clientLogos.map((client, index) => (
                        <div key={index} className="flex justify-center items-center">
                            <img
                                src={client.src}
                                alt={client.alt}
                                className="h-20 sm:h-20 md:h-24 object-contain object-center mx-auto transition-opacity grayscale hover:grayscale-0 rounded-md"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ImageList;