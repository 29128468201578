import React from 'react';

const Head = ({
                  image = '/images/TechnologyBg.webp', // Default image URL
                  heading = '// The Good Coder', // Default heading text
                  subheading = 'Doing Good: One App At A Time', // Default subheading text
                  headingColor = 'text-white', // Default heading color
                  subheadingColor = 'text-white', // Default subheading color
                  headingSize = 'text-4xl', // Default heading size
                  subheadingSize = 'text-lg', // Default subheading size
                  padding = 'p-8', // Default padding
                  backgroundPosition = 'center', // Default background position
                  icon = '', // Default icon 
                  maxHeight='300'
              }) => {
    
    return (
        <div
            className={`p-3 relative ${padding} bg-cover bg-center flex justify-center items-center`}
            style={image ? {backgroundImage: `url(${image})`, backgroundPosition} : {}}
        >
            {/* Content */}
            <div className="relative z-10 text-center">
                {icon &&
                    <img src={icon} alt={`${heading} icon`} className="mb-4 mx-auto w-40 h-40"/>} {/* Centered Icon */}
                <h3 className={`${headingSize} ${headingColor} mb-4 text-shadow`}>{heading}</h3>
                <p className={`${subheadingSize} ${subheadingColor} text-shadow`}>{subheading}</p>
            </div>
        </div>
    );
};

export default Head;
