import React from 'react';
import NavMenu from './NavMenu';
import {useTransition} from './../contexts/TransitionContext';
import {PopupWidget} from "react-calendly";

const Layout = ({children}) => {
    const {isFading} = useTransition();

    return (
        <>
            <div
                className={`transition-all ${isFading ? "bg-black" : "bg-gray-900"}`} // Change background color when fading
            >
                <NavMenu/>
                <main>{children}</main>
            </div>
            {/* Footer */}
            <footer className="bg-black text-white text-center py-4">
                <p>&copy; {new Date().getFullYear()} The Good Coder - Building Technology for a Better World</p>
            </footer>
            <PopupWidget
                url="https://calendly.com/effectify"
                rootElement={document.getElementById("root")}
                text="Schedule Your Free Consultation"
                textColor="#ffffff"
                color="#00a2ff"
            />
        </>
    );
};

export default Layout;
