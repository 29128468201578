import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Use your auth context

const ProtectedRoute = ({ element }) => {
    const { isLoggedIn } = useAuth(); // Get the login status from context

    if (!isLoggedIn) {
        return <Navigate to="/login" replace />; // Redirect to login page if not logged in
    }

    return element; // Render the requested element (Dashboard) if logged in
};

export default ProtectedRoute;
