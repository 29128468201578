import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'; // Import the useAuth hook
import authService from '../../services/authService';
import cosmicBackgroundImage from "../../assets/img/TechnologyBg.webp";
import Button from "../../components/controls/Button";
import Header from "../../components/controls/Header";
import {Helmet} from "react-helmet"; // Your login API service

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const { login } = useAuth(); // Get the login function from context
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrorMessage('');

        try {
            setLoading(true);
            const response = await authService.login(email, password); // Call login API
            setLoading(false);
            if (response && response.token) {
                localStorage.setItem('token', response.token); // Store token
                login(); // Update the context to reflect logged in state
                navigate('/dashboard'); // Redirect to dashboard
            } else {
                setErrorMessage('Invalid credentials');
            }
        } catch (error) {
            setLoading(false);
            setErrorMessage('Login failed. Please try again.');
        }
    };

    return (
        <section className="relative min-h-[100vh] bg-cover bg-center"
                 style={{ backgroundImage: `url(${cosmicBackgroundImage})` }}>

            <Helmet>
                <title>Login // Good coder</title>
                <meta name="description" content="Log In" />
            </Helmet>
            
            {/* Overlay */}
            <div className="absolute inset-0 bg-black opacity-40 z-10"></div>

            {/* Star Layers */}
            <div id="stars" className="star-layer min-h-[80vh]"></div>
            <div id="stars2" className="star-layer min-h-[80vh]"></div>
            <div id="stars3" className="star-layer min-h-[80vh]"></div>
            <div id="stars4" className="star-layer min-h-[80vh]"></div>

            {/* Content */}
            <div className="relative flex items-center justify-center min-h-[80vh] z-20">
                <div className="text-center text-white py-24">
                    <div className="bg-black bg-opacity-50 p-8 rounded-lg shadow-lg w-full sm:w-96">
                        <Header heading="h2">Login</Header>
                        <form onSubmit={handleSubmit}>
                            {/* Email Field */}
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-white text-sm">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={handleChange}
                                    placeholder="Email"
                                    className="mt-2 p-3 w-full bg-gray-800 text-white border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>

                            {/* Password Field */}
                            <div className="mb-6">
                                <label htmlFor="password" className="block text-white text-sm">Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={handleChange}
                                    placeholder="Password"
                                    className="mt-2 p-3 w-full bg-gray-800 text-white border border-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                            </div>

                            {/* Submit Button */}
                            <Button
                                disabled={loading}
                                type="submit"
                                className="mt-4 w-full py-3"
                            >
                                {loading ? "Logging in..." : "Login"}
                            </Button>
                        </form>

                        {/* Error Message */}
                        {errorMessage && (
                            <div className="mt-4 text-red-500 text-center text-sm">
                                {errorMessage}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
